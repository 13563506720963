<template>
  <div class="loading" v-show="this.$store.state.loading">
    <van-loading size="2rem" color="#FFE6FF" text-color="#0080FF" text-size="0.4rem">加载中...</van-loading>
  </div>
</template>
 
<script>
  export default {
    name: 'LoadingComponent',
    data () {
      return {}
    },
    computed: {}
  }
</script>
<style scoped>
  .loading{
    position: fixed;
    top:0;
    left:0;
    z-index:121;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .van-loading {
    width: 3rem;
  	height: 3rem;
    margin:7.5rem auto;
  }
</style>